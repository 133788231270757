import React from "react"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby";
// components
import {
    FirstScreen
} from '../components/Video'
import Pagination from "../ui-kit/Pagination"
// global
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ua/global.yaml"

const VideoPage = ({location, data, pageContext}) => {
    const videos = data.video.edges;
    const categories = data.categories.edges;
    const {humanPageNumber, numberOfPages, previousPagePath, nextPagePath, slug} = pageContext
    
    const videoLocal = {
        langBase: "/ua",
        YAMLGlobal,
    }
    
    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '',
            title: YAMLGlobal.menu.video
        }
    ]
    
    return(
        <Layout local={videoLocal} location={location}>
            <SEO title={YAMLGlobal.menu.video} lang='ua' />
            <Breadcrumbs list={list}/>
            <FirstScreen
                categories={categories} 
                videos={videos}
                Pagination={() => <Pagination
                    parentPath={`/ua/video/category/${slug}`}
                    nextPage={nextPagePath}
                    previousPage={previousPagePath}
                    countOfPages={numberOfPages}
                    currentPage={humanPageNumber}
                />}
                activeSlug={slug}
            />
            <FormOrder wave="white"/>
        </Layout>
    )
}

export const query = graphql`
    query($skip: Int!, $limit: Int!, $slug: String!) {
        video: allDirectusVideo(
            filter: {category: {slug: {eq: $slug}}}, 
            limit: $limit
            skip: $skip) {
            edges {
                node {
                    title_ua
                    slug
                    id
                    image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        },
        categories: allDirectusVideosCategory {
            edges {
              node {
                id,
                slug,
                title_ua
              }
            }
        }
    }
`
export default VideoPage;